import { Canvas } from '@react-three/fiber';
import React, { FC, Suspense } from 'react';
import { PCFSoftShadowMap } from 'three';
import './ThreeCanvas.css';

interface ThreeCanvasProps {
  useDefaultLight?: boolean
}

const ThreeCanvas: FC<ThreeCanvasProps> = (props) => {

  const shadowSize = 1024
  const defaultObjects:JSX.Element[] = []
  if(props.useDefaultLight) {
    defaultObjects.push(<ambientLight key={0} />)
    defaultObjects.push(
      <directionalLight 
        key={1} 
        castShadow 
        shadow-mapSize-width={shadowSize}
        shadow-mapSize-height={shadowSize}
        position={[10, 10, 10]} 
      />
    )
  }

  return (
    <div className="ThreeCanvas">
      <Suspense fallback={<div>Loading... </div>}>
        <Canvas 
          shadows
          gl={{ 
            alpha: true, 
            antialias: true, 
            // This lets you export the rendered image from the canvas
            // preserveDrawingBuffer: true 
          }}
          gl-shadowmap-type={PCFSoftShadowMap}
        >
          {defaultObjects}
          {props.children}
        </Canvas>
      </Suspense>
    </div>
  );
}

export default ThreeCanvas;
