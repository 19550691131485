import * as React from 'react';

import styled from 'styled-components';
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import { Heading } from '../Heading/Heading';
import { Link } from '../Link/Link';
import ThreeCanvas from '../ThreeCanvas/ThreeCanvas';
import NumberDisplay3D from '../ThreeElements/NumberDisplay3D/NumberDisplay3D';
import { Vector3 } from 'three';

const FlexContentSection = styled(ContentSection)`
  display: flex;
  padding-top: 70px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  a {
    width: 250px;
    padding: 18px 15px;
    border-radius: 15px;
  }
`;

const WelcomeParagraph = styled.p`
  width: 500px;
  max-width: 80%;
  text-align: left;
  font-size: 20px;
`;

const FlexLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  a {
    padding: 5px;
  }
`;

const YearContainer = styled.div`
  position: relative;
  height: 80vh;
  width: 100%;
  z-index: 0;

  display: grid;
  justify-items: center;
  align-items:start;
  grid-template-columns: 100%;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
`;

const WelcomeHeading = styled(Heading)`
  max-width: 300px;
`;

const WelcomeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
`;

interface IWelcomeMobileState {
  year: number
}

class WelcomeMobile extends React.Component<any, IWelcomeMobileState> {
  constructor(props) {
    super(props)
    this.state = {year:2021}
  }

  updateYear(thisRef: React.Component) {
    thisRef.setState({year:2022})
  }

  public render() {
    return (
      <FlexContentSection background={welcome_background} backgroundFadeBottom={true}>
        <YearContainer>
          <WelcomeContent>
            <WelcomeHeading>Willkommen zur Neujahrs-Spendenaktion der Informationsfabrik</WelcomeHeading>
            <WelcomeParagraph>Spenden statt schenken <br/> - das ist unser diesjähriges Motto zum neuen Jahr. <br/> 
              Wir unterstützen zwei Organisationen - und Sie bestimmen wer wie viel bekommt! 
            </WelcomeParagraph>
          </WelcomeContent>
          <ThreeCanvas useDefaultLight={true}>
            <NumberDisplay3D 
              numberToDisplay={this.state.year} 
              groupOptions={{position:[0,-4.3,-3], rotation:[-Math.PI/16,1.5*-Math.PI/16,0]}} 
              onCompleteIntro={this.updateYear} 
              parentComponent={this} 
              entryFromOffset={new Vector3(10, 0, 5)}
              createFloor={true} 
              numberReferenceOverwrite={[[2,2,2,2],[0,0,0,0],[2,2,2,2],[2,1,0,0]]}
              rotationDirection={-1} />
          </ThreeCanvas>
        </YearContainer>
      </FlexContentSection>
    );
  }
}

export default WelcomeMobile;
