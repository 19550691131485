import { useFrame } from '@react-three/fiber';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useEffect } from 'react';
import * as THREE from 'three';
import { CompressedPixelFormat, Euler, Vector3 } from 'three';
import Box3D from '../Box3D/Box3D';
import Text3D from '../Text3D/Text3D';
import { gsap } from 'gsap';
import './NumberedBox3D.css';

interface INumberedBox3DProps {
  id: number;
  groupOptions: JSX.IntrinsicElements['group'];
  boxMeshOptions?: JSX.IntrinsicElements['mesh'];
  number: number;
  countStep: number;
  rotationDirection: number;
  introTimeline?: gsap.core.Timeline;
  hoverTimeline?: gsap.core.Timeline;
  entryFromPoint?: Vector3;
  hideNumber?: boolean;
  hideNumberSymbol?: string;
  numberReferenceOverwrite?: number[];
  // additionalAnimation?: (ref: React.MutableRefObject<THREE.Group>) => void;
}

function numberCheck(number:number):number {
  return number > 9 ? number % 10 : number < 0 ? number % 9 + 10 : number
}

function getBackIndexFromEuler(rotation:number):number {
  const temp_frontIndex = (1 + Math.round( rotation / (Math.PI/2))) % 4
  const temp_backIndex = temp_frontIndex > 1 ? temp_frontIndex-2 : temp_frontIndex+2
  return temp_backIndex
}

function NumberedBox3D(props: INumberedBox3DProps) {

  const outerGroup = useRef<THREE.Group>(null!)
  const hoverGroup = useRef<THREE.Group>(null!)
  const group = useRef<THREE.Group>(null!)
  const axeshelper = new THREE.AxesHelper(1)

  const textAdjustments = {
    horizontal: -0.35,
    vertical: -0.4,
    depth: -0.29,
    scale: 0.8,
  }

  const rotations = [
    new Euler(Math.PI/2,0,0),
    new Euler(0,0,0),
    new Euler(-Math.PI/2,0,0),
    new Euler(Math.PI,0,0),
  ]

  const [initialNumber, setInitialNumber] = useState(props.number);
  const [previousNumber, setPreviousNumber] = useState(props.number);

  const [privateTimeline, setPrivateTimeline] = useState(gsap.timeline({}))
  const [forceUpdate, setForceUpdate] = useState(0);

  const [hiddenNumbersExist, setHiddenNumbersExist] = useState(props.hideNumber)
  const [hideReference, setHideReference] = useState(
    [0,1,2,3].map( i => {
      return props.hideNumberSymbol ?? '?'
    })
  ) 

  const [numberReference, setNumberReference] = useState(
    props.numberReferenceOverwrite ?? [0,1,2,3].map( i => {
      return numberCheck(-props.countStep + initialNumber + (props.countStep*i))
    })
  )

  const [desiredRotation, setDesiredRotation] = useState(0);

  useEffect( () => {
    if (!props.hideNumber && hiddenNumbersExist) {
      const currentBackIndex = getBackIndexFromEuler(group.current.rotation.x)
      privateTimeline.to(group.current.rotation, {
        x: desiredRotation + Math.PI * 2,
        ease: "elastic.inOut(1, 0.3)",
        duration: 1.5,
        data: { rotatingTo: props.number },
        onUpdateParams: [numberReference, hideReference, setForceUpdate],
        onUpdate: function(numberReference, hideReference, setForceUpdate) {
          const temp_backIndex = getBackIndexFromEuler(this.targets()[0].x)
          if(hideReference[temp_backIndex] !== numberReference[temp_backIndex].toString()) {
            hideReference[temp_backIndex] = numberReference[temp_backIndex].toString()
            setForceUpdate(numberReference[temp_backIndex])
          }
        },
        onComplete: () => {
          setHiddenNumbersExist(false)
        }
      })
      setDesiredRotation(desiredRotation + Math.PI * 2)
    }
  }, [props.hideNumber]);

  useEffect( () => {
    if(props.number !== previousNumber) {

      if(!privateTimeline.isActive()) privateTimeline.clear()

      if(props.number === (previousNumber + props.countStep) % 10) {

        // Stepping over one Number
        privateTimeline.to(group.current.rotation, {
          x: desiredRotation + (Math.PI/2 * props.rotationDirection),
          ease: "elastic.inOut(1, 0.3)",
          duration: 1.5,
          data: { rotatingTo: props.number },
          onCompleteParams: [privateTimeline, numberReference, props.countStep],
          onComplete: function(privateTimeline, numberReference, countStep) {
            const temp_frontIndex = (1 + Math.round( this.targets()[0].x / (Math.PI/2))) % 4
            const temp_backIndex = temp_frontIndex > 1 ? temp_frontIndex-2 : temp_frontIndex+2
            numberReference[temp_backIndex] = numberCheck(numberReference[temp_backIndex] + (countStep * 4))
          }
        })
        setDesiredRotation(desiredRotation + Math.PI/2)
        setPreviousNumber(props.number)

      } else {

        // Stepping over Multiple Numbers
        const currentBackIndex = getBackIndexFromEuler(group.current.rotation.x)
        const newNumbers = [0,1,2,3].map( i => {
          return {
            backindex: (currentBackIndex+1+i) % 4,
            number: numberCheck(-props.countStep + props.number + (props.countStep*i))
          }
        })
        privateTimeline.to(group.current.rotation, {
          x: desiredRotation + Math.PI * 2,
          ease: "elastic.inOut(1, 0.3)",
          duration: 1.5,
          data: { rotatingTo: props.number },
          onUpdateParams: [numberReference, newNumbers, setForceUpdate],
          onUpdate: function(numberReference, newNumbers, setForceUpdate) {
            const temp_backIndex = getBackIndexFromEuler(this.targets()[0].x)
            newNumbers.forEach( el => {
              if(el.backindex === temp_backIndex && numberReference[el.backindex] !== el.number) {
                numberReference[el.backindex] = el.number
                setForceUpdate(el.number)
              }
            })
          }
        })
        setDesiredRotation(desiredRotation + Math.PI * 2)
        setPreviousNumber(props.number)

      }
    }
  }, [props.number])

  useLayoutEffect( () => {
    // Animation of boxes flying into view
    const iterator = {id: props.id,progress: 0}
    if(props.entryFromPoint && props.introTimeline) {
      const temp_pos = new Vector3();
      outerGroup.current?.position.set(...props.entryFromPoint.toArray())
      const curve = new THREE.CubicBezierCurve3(
        props.entryFromPoint,
        props.entryFromPoint.clone().setY(props.entryFromPoint.length()/2),
        temp_pos.clone().setY(props.entryFromPoint.length()/2),
        temp_pos,
      );

      props.introTimeline.to(
        iterator, 
        {
          progress: 1, 
          ease: "power2.out",
          duration: 3,
          delay: 0,
          onUpdateParams: [outerGroup.current?.position, curve],
          onUpdate: function(pos:Vector3, curve:THREE.CubicBezierCurve3) {
            pos.copy(curve.getPointAt(this.targets()[0].progress))
          },
          onComplete: function() {
            //console.log(`ID ${this.targets()[0].id} is in place`)
          },
        },
        "<+=25%"
      )
      props.introTimeline?.data.configuredIds.push(props.id)
    }

    // Configure hover animation
    if(props.hoverTimeline) {

      const shakeInitTimeline = gsap.timeline({})
      const shakeTimeline = gsap.timeline({})

      //Move up
      shakeInitTimeline.to(
        hoverGroup.current?.position,
        {
          y: 0.6, 
          duration: 0.1,
          ease: "power2.out",
        }
      )
      
      //Shake
      for (let i = 0; i < 4; i++) {
        shakeTimeline.to(
          hoverGroup.current?.rotation,
          {
            z: (i % 2 === 0 ? -0.1 : 0.1),
            // y: (i % 2 === 0 ? -0.1 : 0.1),
            duration: 0.1,
          }
        ),
        (i === 0 ? "<+=25%" : ">")
      }
      shakeTimeline.to(
        hoverGroup.current?.rotation,
        {
          z: 0,
          // y: 0,
          duration: 0.05,
        }
      )
      shakeInitTimeline.add(shakeTimeline)

      shakeInitTimeline.to(
        hoverGroup.current?.position,
        {
          y: 0, 
          duration: 0.4,
          ease: "bounce.out",
        },
        ">-=0.2s"
      )

      props.hoverTimeline.add(
        shakeInitTimeline,
        "<+=50%"
      )

    }

    return () => {
      props.introTimeline?.kill()
      props.hoverTimeline?.kill()
    }
  }, [])
  
  const defaultPosition = new Vector3(textAdjustments.horizontal,textAdjustments.vertical,textAdjustments.depth)

  const numbers3D = numberReference.map( (num, i) => {
    return (
      <Text3D 
        key={i}
        text={hiddenNumbersExist ? hideReference[i] : num.toString()} 
        textOptions={{size:1, height:1}} 
        mesh={{position:defaultPosition.clone().applyEuler(rotations[i]), rotation:rotations[i], scale:textAdjustments.scale}} 
      />
    );
  });

  return (
    <group // outerGroup for casual/temporary animations / no permanent effect
      ref={outerGroup}
    >
      <group // hoverGroup for hover animation
        ref={hoverGroup}
      >
        <group // innerGroup for rotation when the number has to change (permanently)
          {...props.groupOptions}
          ref={group}
        >
          <Box3D 
            {...props.boxMeshOptions}
            castShadow
            receiveShadow
            // scale={(props.groupOptions.position as Vector3).x > 2 ? 1.1 : 1} 
            scale={1}
          />
          {numbers3D}
          {/* <primitive object={axeshelper} /> */}
        </group>
      </group>
    </group>
  );
}

export default NumberedBox3D;
