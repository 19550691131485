import * as React from 'react';
import { breakpoints } from 'src/variables';
import styled from 'styled-components';
import { Heading } from '../Heading/Heading';

const ModalContentSection = styled.section`
  padding: 20px;
  h1 {
    text-align: left;
  }

  p {
    font-size: 18px;
  }
  @media (max-width:${breakpoints.mobile}px) {
    padding: 0;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  justify-content: space-between;
  > div { 
    max-width: 45%;
  }

  @media (max-width:${breakpoints.mobile}px) {
    flex-direction: column;
    > div {
      width: 100%;
      max-width: 100%;
      &:first-child {
        margin-right: 0;
      }
    }
  }
`;

const ModalHeading = styled(Heading)`
  
  & + p {
    margin-bottom: 50px;
  }
`;

const CharityHeading = styled.h1`
  margin-bottom: 0;
`;

const StyledTable = styled.table`
  margin-bottom: 50px;
  tr {
    > td:first-child {
      font-weight: bold;
    }

    > td:first-child:NOT([colspan]) {
      padding-right: 20px;
    }
  }
  td {
    vertical-align: top;
  }
`;

class ModalContent extends React.Component {

  public render() {
    return (
      <ModalContentSection>
        <ModalHeading>Noch eine Herzensangelegenheit</ModalHeading>
        <p>Verschenken Sie selbst eine Spende zum neuen Jahr! 
          <br/> Jeder noch so kleine Betrag kann das Lächeln eines Menschen bedeuten.  
        </p>
        <FlexLayout>
          <div>
            <CharityHeading>Bürger für Münster - Mentorenprogramm</CharityHeading>
            <p>Sie unterstützen das Mentorenprogramm der Stiftung Bürger für Münster mit der direkten Überweisung Ihres Wunschbetrags an folgendes Konto:</p>
            <StyledTable>
              <tbody>  
                <tr><td colSpan={2}>Stiftung Bürger für Münster</td></tr>
                <tr><td>IBAN</td><td>DE16 4005 0150 0000 3930 09</td></tr>
                <tr><td>BIC</td><td>WELADED1MST</td></tr>
                <tr><td>Bank</td><td>Sparkasse Münsterland-Ost</td></tr>
                {/* <tr><td>Zweck</td><td>Wünschewagen</td></tr> */}
              </tbody>
            </StyledTable>
          </div>
          <div>
            <CharityHeading>skate-aid</CharityHeading>
            <p>Sie unterstützen skate-aid mit der direkten Überweisung Ihres Wunschbetrags an folgendes Konto:</p>
            <StyledTable>
              <tbody>
                <tr><td colSpan={2}>skate-aid international e.V.</td></tr>
                <tr><td>IBAN</td><td>DE 82 1005 0000 0190 8260 96</td></tr>
                <tr><td>BIC</td><td>BELADEBEXXX</td></tr>
                <tr><td>Bank</td><td>Berliner Sparkasse</td></tr>
                {/* <tr><td>Zweck</td><td>„Spende Allwetterzoo“ und die jeweilige Adresse für eine Quittung</td></tr> */}
              </tbody>
            </StyledTable>
          </div>
        </FlexLayout>
      </ModalContentSection>
    );
  }
}

export default ModalContent;