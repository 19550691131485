import * as React from 'react';
 
// @ts-ignore
import { withScroll } from 'react-window-decorators';
import styled from 'styled-components';
import infab_logo from '../../assets/images/informationsfabrik_logo.svg';
import MenuBarItem from './MenuBarItem';

const MenuBarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 105px;
  z-index: 999;
  display:flex;
  background: rgba(255,255,255,0);
  align-items: center;
  padding: 0 120px 0 120px;
  transition: 0.5s ease-in-out opacity;
  &.menu-bar--fade-out{
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }

  ul {
    justify-self: flex-end;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
  }
`;

const InfabLogoEl = styled.img`
  height: 100%;
  background: rgba(255,255,255, 0.8);
  padding: 10px;
  padding-bottom: 20px;
`;

class MenuBar extends React.Component {

  private static readonly MENU_ITEMS = [
    ['Herzensangelegenheit', 'matterOfHeart'],
    ['Die Organisationen', 'charities'],
    ['Zur Abstimmung', 'yourVote'],
  ];

  public render() {
    return (
      <MenuBarContainer className={this.getClassName()}>
        <InfabLogoEl src={infab_logo}/>
        <ul>
          {MenuBar.MENU_ITEMS.map( (item, i) => 
            <MenuBarItem anchor={item[1]} key={i}>{item[0]}</MenuBarItem>)}
        </ul>
      </MenuBarContainer>
    );
  }

  public getClassName = () => {
    return (this.props as any).scrollPositionY > 200 ? 'menu-bar--fade-out': '';
  }
}

export default withScroll(MenuBar);
