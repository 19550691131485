import * as React from 'react';
import { Provider } from 'react-redux';
import './App.css';

import { compose, createStore } from 'redux';
// @ts-ignore
import { createClient } from 'redux-socket.io-connect';
import { rootReducer } from 'src/reducers';
import styled from 'styled-components';
import CampaignDetails from '../CampaignDetails/CampaignDetails';
import Charities from '../Charities/Charities';
import Survey from '../Survey/Survey';

// @ts-ignore
import withSizes from 'react-sizes';
import * as io from 'socket.io-client';
import { breakpoints } from 'src/variables';
import MenuBar from '../MenuBar/MenuBar';
import MenuBarMobile from '../MenuBarMobile/MenuBarMobile';
import Welcome from '../Welcome/Welcome';
import WelcomeMobile from '../WelcomeMobile/WelcomeMobile';

import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import SurveyEndedModalContent from '../Survey/SurveyEndedModalContent';

const socket = io('https://infab-xmas.azurewebsites.net/', {transports: ['websocket', 'polling', 'flashsocket']});
// const socket = io('http://localhost:8080');
const client = createClient(socket);

const store = createStore(rootReducer, compose(
  client,
  // devTools
));

const AppContainer = styled.section``;
const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  // gap: 2rem;
  background: white;
`;

interface IAppProps {
  isMobile: boolean;
}

class App extends React.Component<IAppProps, any> {
  
  private fullPageRef: React.RefObject<any>;
  private contentArray = [
    this.props.isMobile ? <WelcomeMobile key={0}/> : <Welcome key={0}/>
    ,<CampaignDetails key={1}/>
    ,<Charities key={2}/>
    ,<Survey key={3}/>
  ];
  private content;

  constructor(props) {
    super(props);
    this.state = {
      isSurveyEndedModalOpen: true
    };
    this.onCloseModal = this.onCloseModal.bind(this);
     
    this.content = this.contentArray;

    this.fullPageRef = React.createRef();
  }

  public render() {
    const { isSurveyEndedModalOpen } = this.state;
    return (
      <Provider store={store}>
        <AppContainer id="outerContainer">
          {this.props.isMobile ? <MenuBarMobile/> :<MenuBar/>}
          <MainContainer id="pageWrap">
            <Modal 
              classNames={{
                modal: 'transparent-modal',
                overlay: 'transparent-overlay',
              }}
              open={isSurveyEndedModalOpen}
              onClose={this.onCloseModal} center={true}>
              <SurveyEndedModalContent/>
            </Modal>
            {this.content}
          </MainContainer>
        </AppContainer>
      </Provider>
    );
  }

  public onCloseModal() {
    this.setState({ isSurveyEndedModalOpen: false });
  }

}

export const mapSizesToProps = (sizes : any) => ({
  isMobile: sizes.width < breakpoints.mobile,
});

export default withSizes(mapSizesToProps)(App)