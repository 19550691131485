import { MeshProps, PlaneGeometryProps } from '@react-three/fiber';
import { FC } from 'react';
import './Plane3D.css';

interface Plane3DProps {
  meshProps: MeshProps;
  planeProps?: PlaneGeometryProps;
}

const Plane3D: FC<Plane3DProps> = function(props) {

  return (
    <mesh 
      receiveShadow 
      {...props.meshProps}
    >
      <planeGeometry 
        attach="geometry" 
        args={[3,3,3]} 
        {...props.planeProps} />
      <shadowMaterial opacity={0.3} />
    </mesh>
  );
} 

export default Plane3D;
