import * as React from 'react';

import { breakpoints, theme } from 'src/variables';
import styled from 'styled-components';
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import { Heading } from '../Heading/Heading';



const MatterOfHeartContainer = styled.div`
  display: flex;
  justify-content: center;

  // background: rgba(255,255,255,0.3);
  padding: 30px;

  @media (max-width:${breakpoints.mobile}px) {
    padding: ${theme.mobile.padding};
    flex-direction: column;
  }
`;

const CampaignDetailsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width:${breakpoints.mobile}px) {
    padding: ${theme.mobile.padding};
  }
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  padding: 40px 100px;
  background: rgba(255,255,255,0.6);

  @media (max-width:${breakpoints.mobile}px) {
    padding: ${theme.mobile.padding};
    background: none;
  }
`;

const EmphasizedParagraph = styled.p`
  font-weight: bold;
`;

const FlexContentSection = styled(ContentSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width:${breakpoints.mobile}px) {
    justify-content: flex-start;
  }

  & > div {
    margin: 50px 0px;
  }

  // > div {
  //   &:first-child {
  //     padding-left: 100px;
  //     width: 50%;
  //   }

  //   &:nth-child(2){
  //     margin-right: 100px;
  //     align-self: flex-end;
  //     width: calc(50% - 100px);
  //   }

    

  //   @media (max-width:${breakpoints.mobile}px) {
  //     width: 100% !important;

  //     &:first-child {
  //       padding-left: ${theme.mobile.padding};
  //     }

  //     &:nth-child(2){
  //       margin-right: 0;
  //     }
  //   }
  // }
`;



class CampaignDetails extends React.Component {
  public render() {
    return (
      <FlexContentSection id="matterOfHeart" background={welcome_background} backgroundFadeTop={true} backgroundFadeBottom={true}>
          <CampaignDetailsContainer>
            <VerticalFlexContainer>
              <Heading>Die Neujahrs-Spendenaktion</Heading>
              <p> 
              Wir starten das neue Jahr mit einer guten Tat: 2022 beginnen wir mit einer großen Spendenaktion zu Gunsten zweier
              karitativer Organisationen. Damit möchten wir unsere Wertschätzung für die wichtige Arbeit der ausgewählten Organisationen
              ausdrücken und einen finanziellen Beitrag leisten.
              </p>
              <EmphasizedParagraph>Die Informationsfabrik spendet in diesem Jahr 3.000 Euro für den guten Zweck!</EmphasizedParagraph>
              <p> 
              Unsere Mitarbeiter haben zwei Organisationen ausgewählt, auf diese wird der Betrag von 3.000 Euro aufgeteilt
              und Sie können bestimmen, wer wie viel bekommt.
              </p>
              <EmphasizedParagraph> 
              Wie das geht? Einfach in der Navigation auf „Zur Abstimmung“ klicken oder nach unten scrollen und Ihre Stimme abgeben.
              Welche Organisation möchten Sie mit Ihrer Stimme unterstützen? Stimmen Sie bis zum 28.02.2022 für Ihr Herzensprojekt ab.
              </EmphasizedParagraph>
            </VerticalFlexContainer>
          </CampaignDetailsContainer>
          {/* <MatterOfHeartContainer>
            <VerticalFlexContainer>
              <Heading>Herzensange&shy;legenheit</Heading>
              <p> 
              Unsere Mitarbeiter haben zwei Organisationen ausgewählt, auf diese wird der Betrag von 3.000 Euro aufgeteilt
              und Sie können bestimmen, wer wie viel bekommt.
              </p>
              <EmphasizedParagraph> 
              Wie das geht? Einfach in der Navigation auf „Zur Abstimmung“ klicken oder nach unten scrollen und Ihre Stimme abgeben.
              Welche Organisation möchten Sie mit Ihrer Stimme unterstützen? Stimmen Sie bis zum 28.02.2022 für Ihr Herzensprojekt ab.
              </EmphasizedParagraph>
            </VerticalFlexContainer>
          </MatterOfHeartContainer> */}
      </FlexContentSection>
    );
  }
}

export default CampaignDetails;
