import { theme } from 'src/variables';
import styled, { StyledComponent } from "styled-components";

interface IStyledLinkProps {
  inverted?: boolean
}

export const Link = styled.a`
  /* Adapt the colors based on primary prop */
  color: ${(props: IStyledLinkProps) => props.inverted ? '#fff' : theme.brandPrimary};
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid ${() => theme.brandPrimary};
  border-radius: 3px;
  background-color: ${(props: IStyledLinkProps) => props.inverted ? theme.brandPrimary : 'transparent'};

  &:hover {
    background-color: ${() => theme.brandPrimary};
    color: #fff;
  }
` as StyledComponent<"a", any, IStyledLinkProps, never>;
