import { breakpoints } from 'src/variables';
import styled, { StyledComponent } from "styled-components";
import defaultBackgroundImage from '../../assets/images/welcome-bg.png';

interface IContentSectionProps {
  background?: string;
  backgroundFadeTop?: boolean;
  backgroundFadeBottom?: boolean;
}

export const ContentSection = styled.section`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 100%;
  background-image:
    linear-gradient(
      180deg, 
      rgba(255,255,255,${ (props: any) => props.backgroundFadeTop ? 1 : 0 }) 0%, 
      rgba(255,255,200,0) 20%, 
      rgba(255,255,255,0) 80%, 
      rgba(255,255,255,${ (props: any) => props.backgroundFadeBottom ? 1 : 0 }) 100%
    ),
    ${ (props: any) => props.background ? `url(${props.background})` : `url(${defaultBackgroundImage})`}
  ;
  background-size: cover;

  @media (max-width:${breakpoints.mobile}px) {
    background-image:
    linear-gradient(
      180deg, 
      rgba(255,255,255,${ (props: any) => props.backgroundFadeTop ? 1 : 0 }) 0%, 
      rgba(255,255,200,0) 20%, 
      rgba(255,255,255,0) 80%, 
      rgba(255,255,255,${ (props: any) => props.backgroundFadeBottom ? 1 : 0 }) 100%
    ),
    ${ (props: any) => props.background ? `url(${props.background})` : `url(${defaultBackgroundImage})`}
  ;
  }
  
` as StyledComponent<"div", any, IContentSectionProps, never>;

