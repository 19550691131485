import * as React from 'react';

 // @ts-ignore
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { closeModal } from 'src/actions/UIActions';
import { breakpoints } from 'src/variables';
import styled from 'styled-components';
import { Vector3 } from 'three';
import logo_0 from '../../assets/images/logo-sbfm-text.png';
import logo_1 from '../../assets/images/logo-skateaid.png';
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import ModalContent from './ModalContent';
import  './Survey.css';
import SurveyOption from './SurveyOption';

const SurveyOptionsContainer = styled.div`
  z-index: 10;
  align-items: flex-end;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 150px;

  @media (max-width:${breakpoints.mobile}px) {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px;
    #yourVote {
      background: none;
    }
  }
`;

const StyledContentSection = styled(ContentSection)`
  position: relative;
  margin-top: 150px;
`;

const TapToVoteRemark = styled.p`
  display: none;
  padding: 15px 5px;
  text-align: center;
  position: absolute;
  top: calc(50% - 50px);
  width: 100%;
  background: rgba(255,255,255,0.9);
  @media (max-width:${breakpoints.mobile}px) {
    display: block;
  }
`;

function mapStateToProps(state: any) {
  return {
    hasVoted: state.survey.hasVoted,
    isModalOpen: state.ui.modal.open,
  };
}


function mapDispatchToProps(dispatch: any) {
  return({
      closeModal: () => {dispatch(closeModal())}
  })
}

interface ISurveyProps {
  closeModal: () => void;
  isModalOpen: boolean;
  hasVoted: boolean;
}

class Survey extends React.Component<ISurveyProps, any> {

  protected surveyOptions: JSX.Element[] = [
        <SurveyOption key={0} 
          survey_option_index={0} 
          organization_key="mep" 
          logo={logo_0} 
          numberDisplayProps={{
            groupOptions: {
              position:[0,-1,1.7], 
              rotation:[-0.2*Math.PI/16,1.5*Math.PI/16,0]
            },
            entryFromOffset: new Vector3(-10, 0, 1)
          }} />,
        <SurveyOption key={1} 
          survey_option_index={1} 
          organization_key="ska" 
          logo={logo_1} 
          numberDisplayProps={{
            groupOptions: {
              position:[0,-1,1.7], 
              rotation:[-0.2*Math.PI/16,-1.5*Math.PI/16,0]
            },
            entryFromOffset: new Vector3(10, 0, 1)
          }} />
  ]

  public onCloseModal = () => {
    console.log('close modal?')
    this.props.closeModal();
  }

  public render() {
    return (
      <StyledContentSection id="yourVote" background={welcome_background} backgroundFadeTop={true} >
        <SurveyOptionsContainer>
          {this.surveyOptions}
        </SurveyOptionsContainer>
        {!this.props.hasVoted && <TapToVoteRemark>Tippe auf eine der beiden Logos um abzustimmen!</TapToVoteRemark>}
        <Modal 
          classNames={{
            modal: 'transparent-modal',
            overlay: 'transparent-overlay',
          }}
          open={this.props.isModalOpen} 
          onClose={this.onCloseModal} center={true}>
          <ModalContent/>
        </Modal>
      </StyledContentSection>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
