import * as React from 'react';
import styled from 'styled-components';
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import { Heading } from '../Heading/Heading';

const WelcomeHeading = styled(Heading)`
    max-width: 850px;
    margin-left: 100px;
`;

const WelcomeText = styled.p`
  max-width: 850px;
  margin-left: 100px;
  text-align: center;
`;

const FlexContentSection = styled(ContentSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class IEMessage extends React.Component {
  
  public render() {
    return (
      <FlexContentSection background={welcome_background}>
        <WelcomeHeading>Der Internet Explorer wird leider nicht unterstützt</WelcomeHeading>
        <WelcomeText>Wechseln Sie bitte zu einem anderen Browser wie <a href="https://www.mozilla.org/de/firefox/new/">Firefox</a>, <a href="https://www.google.com/intl/de_de/chrome/">Chrome</a> oder <a href="https://www.microsoft.com/de-de/windows/microsoft-edge">Edge</a>. <br/> Vielen Dank für Ihr Verständnis.</WelcomeText>
      </FlexContentSection>
    );
  }
}

export default IEMessage;