
import { breakpoints, theme } from 'src/variables';
import styled from 'styled-components';

export const Heading = styled.h1`
  font-family : 'Lato', cursive;
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  z-index: 10;
  // text-align: center;
  color: ${() => theme.maintextColor};
  @media (max-width:${breakpoints.mobile}px) {
    font-size: 30px;
  }

  &:after {
    margin-top: 1rem;
    margin-bottom: 2rem;
    content: "";
    display: block;
    position: relative;
    top: 1rem;
    left: -50px;
    width: 250px;
    max-width: 50vw;
    border-bottom: 2px solid ${() => theme.brandPrimary};
    @media (max-width:${breakpoints.mobile}px) {
      left: calc(-0.5 * ${theme.mobile.padding});
    }
  }
`;

