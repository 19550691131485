import React, { FC, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { gsap } from 'gsap';

import NumberedBox3D from '../NumberedBox3D/NumberedBox3D';
import './NumberDisplay3D.css';
import Plane3D from '../Plane3D/Plane3D';

export interface INumberDisplay3DProps {
  numberToDisplay?: number;
  countStep?: number;
  rotationDirection?: number;
  groupOptions?: JSX.IntrinsicElements['group'];
  onCompleteIntro?: (parent:React.Component) => void;
  parentComponent?: React.Component;
  entryFromOffset?: Vector3;
  visible?: boolean;
  createFloor?: boolean;
  hideNumbers?: boolean;
  playHoverAnimation?: boolean;
  numberReferenceOverwrite?: number[][];
}

const NumberDisplay3D: FC<INumberDisplay3DProps> = (props) => {

  const defaultNumber = 0
  const boxSpacing = 1.1
  const displayGroup = useRef<THREE.Group>();

  const [numberToDisplay, setNumberToDisplay] = useState(props.numberToDisplay ?? defaultNumber);
  const [digits, setDigits] = useState((props.numberToDisplay ?? defaultNumber).toString().split('').map( c => parseInt(c)));
  const [introTimeline, setIntroTimeline] = useState(() => gsap.timeline({
    delay:0.5,
    paused: true,
    data: { configuredIds: [] },
    onCompleteParams: [ props.parentComponent ], 
    onComplete: function( parent ) {
      // Timeline still completes 3 times before it was properly configured (don't know why?)
      // This check catches that so it is only executed when the animation actually happened
      if (this.data.configuredIds.length === digits.length) {
        if(props.onCompleteIntro) props.onCompleteIntro(parent)
      }
    },
  }));
  const [hoverTimeline, setHoverTimeline] = useState(() => gsap.timeline({
    paused: true,
  }));

  useEffect( () => {
    if((numberToDisplay ?? defaultNumber) != parseInt(digits.map(i=>i.toString()).join(''))) {
      const temp_digits = (numberToDisplay ?? defaultNumber).toString().split('').map( c => parseInt(c) )
      numberBoxes = generateNumberBoxes(temp_digits)
      setDigits(temp_digits)
    }
  })

  useEffect( () => {
    setNumberToDisplay(props.numberToDisplay ?? numberToDisplay)
  }, [props.numberToDisplay])

  useEffect( () => {
    if(props.visible !== false) {
      introTimeline.resume()
    }
  }, [props.visible])

  useEffect( () => {

    let scale:number;
    if(props.playHoverAnimation) {
      scale = 1.1
      hoverTimeline.repeat(-1)
      hoverTimeline.restart()
    } else {
      scale = 1
      hoverTimeline.repeat(0)
    }

    gsap.to(
      displayGroup.current!.scale,
      {
        x: scale,
        y: scale,
        z: scale,
        duration: 0.5,
        ease: "elastic.out",
        overwrite: true,
      }
    )
  }, [props.playHoverAnimation])
  
  const generateNumberBoxes = (digits:number[]) => {
    const temp_numberBoxes:any[] = []
    digits.forEach( (el, i, arr) => {
      const temp_pos_x = ((arr.length-1) * -boxSpacing)/2 + i * boxSpacing
      temp_numberBoxes.unshift(
        <NumberedBox3D 
          key={arr.length - i}
          id={arr.length - i}
          number={el}
          countStep={props.countStep ?? 1} 
          rotationDirection={props.rotationDirection ?? 1}
          groupOptions={{position: new Vector3(temp_pos_x, 0, 0)}} 
          boxMeshOptions={{onClick:()=>{
            // this method was used to test the display. A click on any box of the display would increase the number displayed.
            // setNumberToDisplay(numberToDisplay+(props.countStep ?? 1));
          }}} 
          introTimeline={introTimeline}
          hoverTimeline={hoverTimeline}
          entryFromPoint={props.entryFromOffset ?? new Vector3(10, 0, 5)}
          hideNumber={props.hideNumbers}
          hideNumberSymbol='?'
          numberReferenceOverwrite={props.numberReferenceOverwrite ? props.numberReferenceOverwrite[i] : undefined}
        />
      )
    })
    return temp_numberBoxes;
  }
  let numberBoxes:any[] = generateNumberBoxes(digits)

  return (
    <group ref={displayGroup} {...props.groupOptions}>
      {numberBoxes}
      { props.createFloor && <Plane3D 
        meshProps={{position:[0,-0.5,0], rotation:[-Math.PI/2,0,0]}}
        planeProps={{args:[50,50]}}
      /> }
    </group>
  );
}

export default NumberDisplay3D;
