import { VOTE } from "src/constants/actionTypes";


export interface IVoteAction {
  organization: string;
  type: string;
  meta: any;
}

export const vote = (key: string): IVoteAction => ({
  meta: {
    emit: true
  },
  organization: key,
  type: VOTE
});