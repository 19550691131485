import React, { useRef } from 'react';
import './Text3D.css';

import jsonFont from 'src/assets/fonts/Avenir LT Std 65 Medium_Bold.json'
// import jsonFont from 'src/assets/fonts/Lato_Bold.json'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry, TextGeometryParameters } from 'three/examples/jsm/geometries/TextGeometry'

interface IText3DProps {
  mesh?: JSX.IntrinsicElements['mesh'];
  textOptions?: Partial<TextGeometryParameters>;
  text: string;
}

function Text3D(props: IText3DProps) {

  const mesh = useRef<THREE.Mesh>(null!)

  let font = new FontLoader().parse(jsonFont)

  const textOptions:TextGeometryParameters = {
    font,
    size: 5,
    height: 1,
    ...props.textOptions
  };
  const numberGeometry = new TextGeometry(props.text, textOptions)

  // console.log({text: props.text, position: props.mesh?.position})

  return (
    <mesh 
      {...props.mesh}
      ref={mesh}
    >
      <primitive 
        object={numberGeometry} 
        attach='geometry' 
      />
      <meshStandardMaterial attach='material' color={'black'} />
    </mesh>
  );
}

export default Text3D;
