export const theme = {
    brandPrimary: '#ED2015',
    maintextColor: '#3b3b3a',
    mobile: {
        padding: '25px'
    }
};

export const breakpoints = {
    mobile: 1130
};