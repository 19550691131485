import { CLOSE_MODAL, OPEN_MODAL } from 'src/constants/actionTypes';

const initialState = {
  modal: {
    open: false
  }
};

export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {...state, modal: {...state.modal, open: true }};
    case CLOSE_MODAL:
      return {...state, modal: {...state.modal, open: false }};
    default:
     return state;
  }
}