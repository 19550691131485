import * as React from 'react';
import { theme } from 'src/variables';
import styled from 'styled-components';

const MenuBarItemLi = styled.li`
  display:flex;
  flex-direction: column;
  a {
    font-size: 13px;
    padding: 0 20px;
    flex-grow:1;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: ${theme.brandPrimary};
    text-decoration: none;
    text-transform: uppercase;
      background-position: center;
      transition: background 0.8s;
    &:hover {
      background: rgba(222,222,222,0.7) radial-gradient(circle, transparent 1%, rgba(222,222,222,0.8) 1%) center/15000%;
    }
    &:active {
      background-color: #ED2015;
      background-size: 100%;
      transition: background 0s;
    }
  }
`;

class MenuBarItem extends React.Component<{anchor: string}, any> {

  public render() {
    return (
        <MenuBarItemLi>
            <a href={'#' + this.props.anchor}>{this.props.children}</a>
        </MenuBarItemLi>
      )
  }
}

export default MenuBarItem;