import * as React from 'react';
import { theme } from 'src/variables';
import styled from 'styled-components';

const MenuBarItemA = styled.a`
  color: #999999;
  color: rgba(0,0,0,0.78);
  margin-bottom: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Lato';
  font-weight: bold;
  &:hover, &:active {
    color: ${theme.brandPrimary}
  }
`;

class MenuBarItemMobile extends React.Component<{anchor: string}, any> {

  public closeMenu(){
    const wrapper = document.getElementsByClassName('bm-cross-button')[0];
    const button = wrapper.getElementsByTagName('button')[0];
    button.click();
  }

  public render() {
    return (
        <MenuBarItemA onClick={this.closeMenu} href={'#' + this.props.anchor}>
          {this.props.children}
        </MenuBarItemA>
      )
  }
}

export default MenuBarItemMobile;