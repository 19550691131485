import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './components/App/App';
import IEMessage from './components/UnsupportedBrowserMessage/InternetExplorer';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Internet Explorer 6-11
const isIE = navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;

const content = !isIE ? <App/> : <IEMessage/>;

ReactDOM.render(
  content,
  document.getElementById('root') as HTMLElement
);

serviceWorkerRegistration.unregister();