import * as React from 'react';

// @ts-ignore
import { Shake } from 'reshake';
import styled from 'styled-components';
// import winter_landscape_tree from '../../assets/images/winter_landscape_tree.jpg';
// https://images.pond5.com/gold-glitter-sparkles-texture-dark-088794654_prevstill.jpeg
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import { Heading } from '../Heading/Heading';
import ThreeCanvas from '../ThreeCanvas/ThreeCanvas';
import NumberDisplay3D from '../ThreeElements/NumberDisplay3D/NumberDisplay3D';
import { Vector3 } from 'three';

const WelcomeContent = styled.div`
  max-width: 950px;
  margin-top: 250px;
  margin-bottom: 200px;
  padding: 30px 130px;
  background: rgba(255,255,255,0.6);
  // padding: 30px;
  z-index: 1;
`;

const WelcomeHeading = styled(Heading)`
    max-width: 850px;
`;

const WelcomeText = styled.p`
  max-width: 850px;
  margin-top: 4rem;
  // text-align: center;
`;

const FlexContentSection = styled(ContentSection)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center
`;

const YearContainer = styled.div`
  position: relative;
  height: 80%;
  width: 100%;
  z-index: 0;

  display: grid;
  justify-items: center;
  align-items:center;
  grid-template-columns: 100%;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
`

interface IWelcomeState {
  year: number
}

class Welcome extends React.Component<any, IWelcomeState> {
  constructor(props) {
    super(props)
    this.state = {year:2021}
  }

  updateYear(thisRef: React.Component) {
    thisRef.setState({year:2022})
  }
  
  public render() {
    return (
      <FlexContentSection background={welcome_background} backgroundFadeBottom={true}>
        <YearContainer>
          <WelcomeContent>
            <WelcomeHeading>Willkommen zur Neujahrs-Spendenaktion der Informationsfabrik</WelcomeHeading>
            <WelcomeText>Spenden statt schenken, das ist unser Motto zum neuen Jahr. Wir unterstützen zwei Organisationen – und Sie bestimmen wer wie viel bekommt!  </WelcomeText>
          </WelcomeContent>
          {/* <VoteCountdown numberToDisplay={10} groupOptions={{position:[1,-4.3,-3], rotation:[-Math.PI/16,1.5*-Math.PI/16,0]}} /> */}
          <ThreeCanvas useDefaultLight={true}>
            <NumberDisplay3D 
              numberToDisplay={this.state.year} 
              groupOptions={{position:[1,-4.3,-3], rotation:[-1 * Math.PI/16,1.5*-Math.PI/16,0]}} 
              onCompleteIntro={this.updateYear} 
              parentComponent={this} 
              entryFromOffset={new Vector3(10, 0, 5)}
              createFloor={true} 
              numberReferenceOverwrite={[[2,2,2,2],[0,0,0,0],[2,2,2,2],[2,1,0,0]]}
              rotationDirection={-1}/>
          </ThreeCanvas>
        </YearContainer>
      </FlexContentSection>
    );
  }
}

export default Welcome;
