import { SURVEY_UPDATE, VOTE } from 'src/constants/actionTypes';

const initialState = {
  gff: 0,
  hasVoted: 'mep',
  vwh: 0
};

export const surveyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SURVEY_UPDATE:
      return {...state, ...action.payload};
    case VOTE:
      return {...state,  [action.organization]: state[action.organization] + 1, hasVoted: action.organization};
    default:
     return state;
  }
}