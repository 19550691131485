import * as React from 'react';

import { breakpoints } from 'src/variables';
import styled from 'styled-components';
import logo_0 from '../../assets/images/logo-sbfm-big-crop.png';
import logo_1 from '../../assets/images/logo-skateaid.png';
import welcome_background from '../../assets/images/welcome-bg.png';
import { ContentSection } from '../ContentSection/ContentSection';
import Charity from './Charity';


const CharitiesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (max-width:${breakpoints.mobile}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  img {
    height: 231px;
  }
`;

class Charities extends React.Component {

  protected charities = [
    <Charity
      key={0}
      logo={logo_0} 
      infoUrl="https://www.buerger-fuer-muenster.de/mentorenprogramm/"
      name="Mentorenprogramm Stiftung Bürger für Münster" 
      name_preBreak={null}
    >
      Unser Mentorenprogramm unterstützt erfolgreich Schülerinnen und Schüler in der Phase der Berufsorientierung. Die Mentoren sind an 
      Realschulen und Gesamtschulen in Münster für die Jugendlichen eine Motivationshilfe, die ansonsten keine oder kaum Unterstützung erfahren würden. 
      Sie beantworten Fragen bei der Erstellung von Bewerbungen, üben Vorstellungsgespräche und sind vor allem für ihren Mentee in dieser aufregenden Zeit an ihrer Seite. 
      <br /><br /><b> Diese Hilfe zur Selbsthilfe wird viel und dankbar von den Schülerinnen und Schülern angenommen - ein schöner Erfolg für alle! </b><br /><br /> Mit Coaching Angeboten bereitet 
      die Stiftung Bürger für Münster die Mentoren auf ihre Aufgabe vor und bildet sie fort.
    </Charity>,
    <Charity
      key={1}
      logo={logo_1} 
      infoUrl="https://www.skate-aid.org/"
      name="skate-aid"
      name_preBreak={null}
    >
      skate-aid ist eine gemeinnützige Organisation, die durch die pädagogische Kraft des Skateboardens umfassende Kinder- und Jugendhilfe leistet. 
      Seit der Gründung 2009 durch Skateboard-Pionier Titus Dittmann realisiert sie weltweit soziale Skatepark-Projekte für benachteiligte Kinder und 
      Jugendliche z.B. in Ländern wie Uganda, Namibia, Palästina und Syrien. Die Skateparks und das Skateboard sind die notwendigen Tools, um durch 
      pädagogisch begleitete Skateboard-Workshops die Kids nachhaltig in ihrer Entwicklung zu unterstützen und ihre intrinsische Motivation zu fördern. 
      So lernen sie dabei selbstbestimmt nach dem Hinfallen immer wieder aufzustehen. <br /><br /> <b>Das ist die skate-aid Mission: Wir machen Kinder stark!</b>
    </Charity>,
];
  
  public render() {
    return (
      <ContentSection id="charities" background={welcome_background} backgroundFadeTop={true} backgroundFadeBottom={true}>
          <CharitiesContainer>{this.charities}</CharitiesContainer>
      </ContentSection>
    );
  }
}

export default Charities;
