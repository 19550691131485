import styled, { StyledComponent } from 'styled-components';

export const ParagraphWithHeading = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    text-align: left;
  }
  max-width: 1000px;
  p {
    ${(props: any) => props.backgroundHidden ? 'background: none !important;' : ''}
  }
` as StyledComponent<"div", any, {backgroundHidden?: boolean}, never>;

ParagraphWithHeading.defaultProps = {
  className: 'paragraph-with-heading'
};