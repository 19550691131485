import * as React from 'react';
import { breakpoints } from 'src/variables';
import styled from 'styled-components';
import { Heading } from '../Heading/Heading';

const ModalContentSection = styled.section`
  padding: 20px;
  h1 {
    text-align: left;
  }

  p {
    font-size: 18px;
  }
  @media (max-width:${breakpoints.mobile}px) {
    padding: 0;
  }
`;

const ModalHeading = styled(Heading)`
  
  & + p {
    margin-bottom: 50px;
  }
`;

class SurveyEndedModalContent extends React.Component {

  public render() {
    return (
      <ModalContentSection>
        <ModalHeading>Abstimmung beendet!</ModalHeading>
        <p>
          Vielen Dank an alle Teilnehmer unserer Spendenaktion. Die Abstimmung ist nun beendet. Das Ergebnis sehen Sie, wenn Sie nach unten scrollen.
        </p>

      </ModalContentSection>
    );
  }
}

export default SurveyEndedModalContent;