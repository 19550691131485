import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, { useRef, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import './Box3D.css'
import woodenTexture from 'src/assets/images/wood-texture.jpg'
// https://3dtextures.me/2021/02/15/wood-plywood-001/
import woodTextureColor from 'src/assets/images/Wood_Plywood_Front_001_basecolor.jpg'
import woodTextureDisplacement from 'src/assets/images/Wood_Plywood_Front_001_height.png'
import woodTextureNormal from 'src/assets/images/Wood_Plywood_Front_001_normal.jpg'
import woodTextureRoughness from 'src/assets/images/Wood_Plywood_Front_001_roughness.jpg'
import woodTextureAmbientOcclusion from 'src/assets/images/Wood_Plywood_Front_001_ambientOcclusion.jpg'

import testTextureColor from 'src/assets/images/uv-test.png'

import { TextureLoader, Vector2 } from 'three'

function Box3D(props: JSX.IntrinsicElements['mesh']) {

  const mesh = useRef<THREE.Mesh>(null!)

  // const colorMap = useLoader(TextureLoader, woodenTexture) as THREE.Texture
  let [colorMap, displacementMap, normalMap, roughnessMap, aoMap] = useLoader(TextureLoader, [
    woodTextureColor,
    woodTextureDisplacement,
    woodTextureNormal,
    woodTextureRoughness,
    woodTextureAmbientOcclusion,
  ])
  // let [colorMapTest] = useLoader(TextureLoader, [testTextureColor])
  colorMap.wrapS = THREE.ClampToEdgeWrapping
  colorMap.wrapT = THREE.ClampToEdgeWrapping

  // Debugging UVs...
  // if(mesh.current && mesh.current.scale.x > 1) {
  //   console.log('Formatted UV array')
  //   const test = mesh.current.geometry.attributes.uv.clone()
  //   const myArray:any[] = []
  //   for (let i = 0; i < test.count; i=i+4) {
  //     myArray.push([])
  //     for (let j = 0; j < 4; j++) {
  //       myArray[Math.ceil(i/4)].push(new Vector2(test.getX(i+j), test.getY(i+j)));
  //     }
  //   }
  //   console.log(myArray)
  // }
  // if(mesh.current && mesh.current.scale.x > 1) console.log(mesh.current)

  return (
    <mesh
      {...props}
      ref={mesh}
    >
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial 
        attach="material" 
        // map={colorMapTest}
        map={colorMap}
        // displacement seems to fuck up the textures
        // displacementMap={displacementMap}
        normalMap={normalMap}
        normalScale={new Vector2(1,-1)}
        roughnessMap={roughnessMap}
        aoMap={aoMap}
      />
    </mesh>
  )
}

export default Box3D;