import * as React from 'react';
import { connect } from 'react-redux';
import { vote } from 'src/actions/SocketActions';
import { openModal } from 'src/actions/UIActions';
import { breakpoints } from 'src/variables';
import styled, { keyframes } from 'styled-components';
import { Vector3 } from 'three';
import welcome_background from '../../assets/images/welcome-bg.png';
import ThreeCanvas from '../ThreeCanvas/ThreeCanvas';
import NumberDisplay3D, { INumberDisplay3DProps } from '../ThreeElements/NumberDisplay3D/NumberDisplay3D';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0) scale(1.1);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0) scale(1.1);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0) scale(1.1);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0) scale(1.1);
  }
`;

const grow = keyframes`
  0% {
    transform: scale(1)
  }
  100% {
    transform: scale(1.1)
  }
`;

const glow = keyframes`
  from {
    transform: scale(1);
    filter: drop-shadow(0px 0px 0px rgb(249, 246, 240));
  }

  to {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 50px rgb(249, 246, 240));
  }
`;

const mobileW = 230;
const mobileH  = mobileW * 1.5;
const mobileSignW = 150;

const SurveyOptionContainer = styled.div`

  position: relative;
  height: 80%;
  width: 100%;
  z-index: 0;

  display: grid;
  justify-items: center;
  align-items:center;
  grid-template-columns: 100%;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &.survey-option--can-vote {
    
    img {
      transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    &:hover {
      cursor: pointer;
      img {
        transform: scale(1.1);
      }
      // Shaking the logo is too much, when the boxes are shaking
      // img {
      //   cursor: pointer;
      //   animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
      //   transform: translate3d(0, 0, 0) scale(1.1);
      //   backface-visibility: hidden;
      //   perspective: 1000px;
      // }
    }
  }
  
  &.survey-option--not-voted-for, &.survey-option--voted-for {
    cursor: not-allowed;
  }

  &.survey-option--voted-for {

    & > div {
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(87,227,255,0) 50%, rgba(0,212,255,0) 100%);
    }

    & > div > img {
      // background-color: red;
      // background-position-x: -960px;
      // @media (max-width:${breakpoints.mobile}px) {
      //   background-position-x: -${2 * mobileW}px !important;
      // }
      animation: ${glow} 2s ease 1;
      animation-fill-mode: forwards;
    }
  }

  &.survey-option--not-voted-for {

    & > div {
      background: radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(87,227,255,0) 50%, rgba(0,212,255,0) 100%);
    }

    .survey-option__avatar{
      // background-position-x: -1440px !important;
      @media (max-width:${breakpoints.mobile}px) {
        background-position-x: -${3 * mobileW + 20}px !important;
      }
    }
  }

  @media (max-width:${breakpoints.mobile}px) {
    height: 50vh;
    overflow: hidden;
    width: 100%;
    background: no-repeat center bottom url(${welcome_background});
    background-image:
      linear-gradient(
        180deg, 
        rgba(255,255,255,1) 0%, 
        rgba(255,255,200,0) 10%, 
        rgba(255,255,255,0) 90%, 
        rgba(255,255,255,1) 100%
      ),
      url(${welcome_background})
    ;
    background-size: cover;
    justify-content: center;
    .survey-option__avatar {
      background-position-x: -${mobileW}px !important;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
  height: 100%;
  padding: 10px;
  img {
    object-fit: contain;
    max-width: 100%;
    display: block;
    max-height:231px;
    width: auto;
    height: auto;

    @media (max-width:${breakpoints.mobile}px) {
      max-height: 180px;
      margin-top: 40px;
    }
  }
  
`;

const VoteContainer = styled.div`
  width: 100%;
  height: 600px;
  @media (max-width:${breakpoints.mobile}px) {
    height: 50vh;
  }
`;


function mapStateToProps(state: any, ownProps: any) {
  return {
    count: state.survey[ownProps.organization_key],
    hasVoted: state.survey.hasVoted
  };
}

function mapDispatchToProps(dispatch: any) {
    return({
        openModal: () => {dispatch(openModal())},
        voteFor: (key: string) => {dispatch(vote(key))},
    })
}

interface ISurveyOptionProps {
  logo: string;
  survey_option_index?: number;
  numberDisplayProps?: INumberDisplay3DProps;
  organization_key: string;
  count?: number;
  hasVoted?: string;
  voteFor?: (key: string) => void;
  openModal?: () => void;
}

class SurveyOption extends React.Component<ISurveyOptionProps, any> {

  protected voteContainerRef: React.RefObject<any>

  constructor(props: ISurveyOptionProps){
    super(props);
    this.state = {
      visible: false,
      hovering: false,
    }
    this.voteContainerRef = React.createRef();
    gsap.registerPlugin(ScrollTrigger);
  }

  public voteFor = () => {
    if(!this.props.hasVoted){
      setTimeout(() => {
        this.props.openModal!();
      }, 3000);

      localStorage.setItem('hasVoted2022-newyear', this.props.organization_key);
      this.props.voteFor!(this.props.organization_key);
    }
  }

  public getClassNameList: () => string = () => {
    return !this.props.hasVoted ? 'survey-option--can-vote' : 
      (this.props.hasVoted === this.props.organization_key ? 'survey-option--voted-for' : 'survey-option--not-voted-for');
  }

  public setPositions(signAndAvatar: JSX.Element[]): JSX.Element[] {
    return this.props.survey_option_index === 0 ? signAndAvatar : [signAndAvatar[1], signAndAvatar[0]];
  }

  componentDidMount() {
    // const voteContainerId = `#voteContainer-${this.props.survey_option_index}`
    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: () => {
        if(!this.state.visible && ScrollTrigger.isInViewport(this.voteContainerRef.current)) {
          this.setState({ visible: true })
        }
      }
    });
  }

  public render() {
    return (
      <SurveyOptionContainer 
        onClick={this.voteFor} 
        onMouseEnter={ () => this.setState({hovering: true})}
        onMouseLeave={ () => this.setState({hovering: false}) }
        {...this.props} 
        className={this.getClassNameList()}
      >
        <ImageContainer>
          <img src={this.props.logo}/>
        </ImageContainer>
        <VoteContainer ref={this.voteContainerRef}>
          <ThreeCanvas useDefaultLight={true}>
            <NumberDisplay3D 
              numberToDisplay={this.props.count!} 
              visible={this.state.visible}
              createFloor={true}
              hideNumbers={!this.props.hasVoted}
              // playHoverAnimation={!this.props.hasVoted && this.state.hovering}
              playHoverAnimation={this.props.hasVoted === this.props.organization_key}
              {...this.props.numberDisplayProps}
               />
          </ThreeCanvas>
        </VoteContainer>
     </SurveyOptionContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOption);
