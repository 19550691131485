import * as React from 'react';
import { elastic as Menu } from 'react-burger-menu';
 
 // @ts-ignore
import { withScroll } from 'react-window-decorators';
import { theme } from 'src/variables';
import styled from 'styled-components';
import logo from '../../assets/images/informationsfabrik_logo.svg';
import MenuBarItemMobile from './MenuBarItemMobile';

const MenuBarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 65px;
  z-index: 999;
  display:flex;
  background: rgba(255,255,255,0.8);
  align-items: center;
  padding: 0 15px;
  transition: 0.5s ease-in-out opacity;
  &.menu-bar--fade-out{
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  & > img {
    height: calc(100% - 15px);
    margin: 15px 0px;
  }
`;

const styles = {
  bmBurgerBars: {
    background: theme.brandPrimary,
    height: '2px'
  },
  bmBurgerButton: {
    height: '25px',
    position: 'fixed',
    right: '36px',
    top: '21px',
    width: '36px',
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.8em',
  },
  bmMenu: {
    background: 'rgba(255,255,255,0.8)',
    fontSize: '1.15em',
    padding: '2.5em 1.5em 0',
    width: '280px',
  },
  bmMorphShape: {
    fill: 'rgba(255,255,255,0.8)'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};


class MenuBarMobile extends React.Component {

  private static readonly MENU_ITEMS = [
    ['Herzensangelegenheit', 'matterOfHeart'],
    ['Die Organisationen', 'charities'],
    ['Zur Abstimmung', 'yourVote'],
  ];

  public render() {
    return (
      [<MenuBarContainer key={0} className={this.getClassName()}>
        <img src={logo}/>
      </MenuBarContainer>,
      <Menu key={1} right={true} styles={styles} width={400} pageWrapId="pageWrap" outerContainerId="outerContainer">
       {MenuBarMobile.MENU_ITEMS.map( (item, i) => 
         <MenuBarItemMobile anchor={item[1]} key={i}>{item[0]}</MenuBarItemMobile>)}
      </Menu>]
    ); 
  }

  public getClassName = () => {
    return (this.props as any).scrollPositionY > 200 ? 'menu-bar--fade-out': '';
  }
}

export default withScroll(MenuBarMobile);
