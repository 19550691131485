import * as React from 'react';

import { breakpoints, theme } from 'src/variables';
import styled from 'styled-components';
import { Heading } from '../Heading/Heading';
import { Link } from '../Link/Link';
import { Paragraph } from '../Paragraph/Paragraph';
import { ParagraphWithHeading } from '../ParagraphWithHeading/ParagraphWithHeading';



const CharityContainer = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  // max-width: 600px;
  margin: 50px 50px;
  p {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .paragraph-with-heading {
    flex-grow: 1;
  }

  @media (max-width:${breakpoints.mobile}px) {
    flex-direction: column;
    margin: ${theme.mobile.padding}; 
    h1 {
      padding: ${theme.mobile.padding} ${theme.mobile.padding} 0 ${theme.mobile.padding};
    }
    .paragraph-with-heading {
      p {
        padding: 0 ${theme.mobile.padding} ${theme.mobile.padding} ${theme.mobile.padding};
        background: none;
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 281px;
  padding: 10px;
  img {
    object-fit: contain;
    max-width: 100%;
    display: block;
    max-height:231px;
    width: auto;
    height: auto;
  }
  @media (max-width:${breakpoints.mobile}px) {
    // display: none;
  }
  
`;

interface ICharityProps {
  name: string;
  infoUrl: string;
  logo: string;
  name_preBreak: any;
};

class Charity extends React.Component<ICharityProps, any> {

  constructor(props: ICharityProps){
    super(props);
  }

  public render() {
    return (
      <CharityContainer>
        <ImageContainer><img src={this.props.logo}/></ImageContainer>
        <ParagraphWithHeading>
          <Heading>{this.props.name_preBreak}{this.props.name}</Heading>
          <Paragraph> 
          {this.props.children}
          </Paragraph>
        </ParagraphWithHeading>
        <Link target="_blank" href={this.props.infoUrl}>Weitere Infos</Link>
      </CharityContainer>
    );
  }
}

export default Charity;
